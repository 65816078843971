import { ORGANIZATION_SETTINGS_VALUE, OUTPUT_TYPE_NAME, OUTPUT_TYPE_NAME_KEY } from 'constants/index';
import { Menu, MenuItem } from '@material-ui/core';
import { BsModal, Button, Icon, Label, Loading, Table, HomeOnlineExamNoDataScreen } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOnePaper as getOnePaperApi } from 'services/api/groups/papers';
import { createOrgSharedPapers } from 'services/api/organization/sharedPapers';
import { useClass } from 'store/class';
import { useServerTime } from 'store/serverTime/index';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { ONLINE_EXAM_REQUEST_TYPES, ONLINE_EXAM_LABEL_COLOR } from '../HomeOnlineExam/HomeOnlineExam';
import { UiExamIcons } from './HomeExamPaperProprietary.style';

const SCHEMA = {
  year: {
    name: '學年度',
    defaultValue: '',
  },
  eduSubjectName: {
    name: '學制科目',
    defaultValue: '',
  },
  name: {
    name: '試卷名稱',
    defaultValue: '',
  },
  paperType: {
    name: '試卷類型',
    defaultValue: '',
  },
};

const OrganizationPaperModal = ({ isOpen, onToggleModal, paper }) => {
  const [, { fetchServerTime }] = useServerTime();
  const [isLoading, setIsLoading] = useState(false);
  const { setAlert } = useAlert();
  const { organizationId } = useParams();
  const onOk = async () => {
    setIsLoading(true);
    const serverTimestamp = await fetchServerTime();
    const params = {
      papers: [
        {
          paperId: paper.uid,
          paperName: paper.name,
          paperUpdateTime: serverTimestamp,
        },
      ],
    };
    const { status } = await createOrgSharedPapers(organizationId, params);
    status === 'success' ? setAlert('公開試卷成功', 'success') : setAlert('公開試卷失敗', 'error');
    setIsLoading(false);
    onToggleModal();
  };
  return (
    <BsModal
      open={isOpen}
      title="確定公開為機構試卷？"
      isFull={true}
      isLoading={isLoading}
      cancelDisplay={true}
      onOk={onOk}
      onCancel={onToggleModal}
    >
      公開後，機構內老師將可取用此份試卷，且無法取消公開。
    </BsModal>
  );
};

export const HomeExamPaperProprietary = ({ filterData, onCreateExam, onPrompt }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [onePaperApiData, setOnePaperApiData] = useState(); // 自有試卷
  const [pagination, setPagination] = useState({
    nowPage: 0,
    rowsPage: 10,
    totalPage: 0,
  });
  const [
    {
      myClasses: {
        dataInfo: { users: classUsers, year },
      },
    },
  ] = useClass();
  const { setAlert } = useAlert();
  const { classId } = useParams();

  // 操作
  const ActionComponents = ({ params }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenOrganizationPaperModal, setIsOpenOrganizationPaperModal] = useState(false);
    const [{ myOrganization }] = useUser();
    const isEnabledOrganizationSharedPapersSetting =
      myOrganization.organization?.organizationSharedPapersSetting === ORGANIZATION_SETTINGS_VALUE.ENABLED;
    const { eduSubjectName, name, uid: paperId, download, downloadName, outputTypeName } = params;
    const isFiles = outputTypeName === OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.FILES];
    const isCantCreate = classUsers?.length > 0;
    const onViewSession = () => {
      const url = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/paper/preview/${paperId}`;
      window.open(url);
    };

    const downloadFile = async (u, name) => {
      const fileBlob = await fetch(u).then((res) => res.blob());
      const url = window.URL.createObjectURL(new Blob([fileBlob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    };

    const toggleMenu = (event) => {
      anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
    };

    const toggleOrganizationPaperModal = () => {
      setAnchorEl(null);
      setIsOpenOrganizationPaperModal(!isOpenOrganizationPaperModal);
    };

    return (
      <>
        <UiExamIcons>
          <div tabIndex={0}>
            <Icon.Svg name="Visibility" onClick={onViewSession} />
          </div>
          <div tabIndex={0}>
            <Icon.Svg name="DownloadFile" onClick={() => downloadFile(download, downloadName)} disabled={!download} />
          </div>
          {!isFiles && (
            <div tabIndex={0} style={{ minWidth: '88px' }}>
              <Button
                iconSvg="Plane"
                onClick={isCantCreate ? () => onCreateExam({ eduSubjectName, paperId, name }) : onPrompt}
              >
                派發測驗
              </Button>
            </div>
          )}
          {isEnabledOrganizationSharedPapersSetting && (
            <div tabIndex={0}>
              <Icon.Svg name="Ellipsis" onClick={toggleMenu} />
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={toggleMenu}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem onClick={toggleOrganizationPaperModal}>公開為機構試卷</MenuItem>
              </Menu>
            </div>
          )}
        </UiExamIcons>
        {isOpenOrganizationPaperModal && (
          <OrganizationPaperModal
            isOpen={isOpenOrganizationPaperModal}
            onToggleModal={toggleOrganizationPaperModal}
            paper={params}
          />
        )}
      </>
    );
  };

  const examPaperList = useMemo(() => {
    if (!onePaperApiData) return [];
    const { nowPage, rowsPage } = pagination;
    // 將測驗列表依序做「過濾、搜尋、分頁」
    const filteredPapers = onePaperApiData.papers
      .map((paper) => {
        // 線上測驗或速測 ? 線上測驗 : 紙本卷類
        const paperType = ONLINE_EXAM_REQUEST_TYPES.includes(paper.outputTypeName)
          ? OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.ONLINE]
          : OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.FILES];
        return {
          ...paper,
          year: paper.year,
          // code: paper.attributes.education + paper.attributes.subject, // 下行選項欄位之範例，ex. JMA, JEN, ...
          code: '', // 選項欄位（科目代碼），TODO: API 尚未增加此屬性
          paperType: <Label color={ONLINE_EXAM_LABEL_COLOR[paperType]}>{paperType}</Label>,
        };
      })
      .filter((paper) => !filterData.name || paper.name.includes(filterData.name));
    setPagination((prev) => ({
      ...prev,
      totalPage: filteredPapers.length,
    }));
    const papers = filteredPapers.slice(nowPage * rowsPage, nowPage * rowsPage + rowsPage);
    return papers;
  }, [onePaperApiData, pagination.nowPage, pagination.rowsPage, filterData.name]);

  /* 取得自有試卷 */
  const fetchOnePaperInfo = async () => {
    setIsLoading(true);
    const { status, data } = await getOnePaperApi(classId, year);
    status === 'success' ? setOnePaperApiData(data) : setAlert('取得自有試卷列表失敗', 'error');
    setIsLoading(false);
  };

  /* 換頁 */
  const changePage_Rows = (params) => {
    const { newPage, newRowsPage } = params;
    setPagination((prev) => ({
      ...prev,
      nowPage: newPage,
      rowsPage: newRowsPage,
    }));
  };

  useEffect(() => {
    if (!year) return;
    fetchOnePaperInfo();
  }, [year]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      nowPage: 0,
    }));
  }, [filterData.name]);

  return isLoading ? (
    <Loading />
  ) : examPaperList.length ? (
    <Table
      data={examPaperList}
      schema={SCHEMA}
      changePage_Rows={changePage_Rows}
      totalPage={pagination.totalPage}
      ActionComponents={ActionComponents}
      nowPage={pagination.nowPage}
    />
  ) : (
    <HomeOnlineExamNoDataScreen />
  );
};

OrganizationPaperModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggleModal: PropTypes.func,
  paper: PropTypes.shape,
};
HomeExamPaperProprietary.propTypes = {
  filterData: PropTypes.string,
  onCreateExam: PropTypes.func,
  onPrompt: PropTypes.func,
};
