import { Box } from '@material-ui/core';
import { Button } from 'components';
import QRCode from 'qrcode.react';
import React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { encrypt } from 'utils/crypto';

const URL_SEARCH_PARAM = new URLSearchParams(window.location.search);
const ACCOUNT = URL_SEARCH_PARAM.get('account');
const PASSWORD = URL_SEARCH_PARAM.get('password');

function generateQrCodeLoginUrl({ account, password }) {
  const token = encrypt(JSON.stringify({ account, password }));
  const encodedToken = encodeURIComponent(token);
  const url = `${process.env.REACT_APP_ONELINK_API_DOMAIN}/qr-code-login?token=${encodedToken}`;
  return url;
}

function downloadQrCode() {
  const qrCodeCanvas = document.getElementById('qr-code');
  const pngUrl = qrCodeCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
  let downloadLink = document.createElement('a');
  downloadLink.href = pngUrl;
  downloadLink.download = `${ACCOUNT}.png`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

const QR_CODE_LOGIN_URL = generateQrCodeLoginUrl({ account: ACCOUNT, password: PASSWORD });

/**
 * QRCode 頁
 */

const SvgComponent = ({ account }) => {
  return (
    <svg height="20" width="100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="red">
        {account}
      </text>
      Sorry, your browser does not support inline SVG.
    </svg>
  );
};

export const QRCodePage = () => {
  // 將 SvgComponent 的內容嵌入到 data URL 中
  const svgDataUrl = `data:image/svg+xml;utf8,${encodeURIComponent(
    ReactDOMServer.renderToString(<SvgComponent account={ACCOUNT} />),
  )}`;

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '16px',
          height: '100vh',
        }}
      >
        <Box>
          <QRCode
            id="qr-code"
            size={256}
            value={QR_CODE_LOGIN_URL}
            level={'H'}
            includeMargin
            imageSettings={{
              src: svgDataUrl,
              height: 20,
              width: 120,
              excavate: true,
            }}
          />
        </Box>
        <Box>
          <Button onClick={downloadQrCode}>下載 QRCode</Button>
        </Box>
      </Box>
    </>
  );
};
