import {
	Paper as MatPaper,
	Table as MatTable,
	TableBody as MatTableBody,
	TableCell as MatTableCell,
	TableContainer as MatTableContainer,
	TablePagination as MatTablePagination,
	TableHead as MatTableHead,
	TableRow as MatTableRow
} from '@material-ui/core';
import styled from 'styled-components';

export const UiPaper = styled(MatPaper)`
	display: flex;
	padding: 8px 0;
	box-shadow: none;
	flex-direction: column;
	flex: 1;
`;
export const UiTable = styled(MatTable)`
	min-width: 100%;
`;
export const UiTableBody = styled(MatTableBody)``;
export const UiTableCell = styled(MatTableCell)`
max-width: 200px;
color: #121232;
`;
export const UiFlexTableCell = styled(MatTableCell)`
max-width: 200px;
`;
export const UiTableContainer = styled(MatTableContainer)`
	flex: 1;
`;
export const UiTablePagination = styled(MatTablePagination)``;
export const UiTableHead = styled(MatTableHead)`
	background-color: ${({ theme }) => theme.palette.text.secondary};

`;
export const UiFirstTableRow = styled(MatTableRow)`
background-color: #fff;

& > th {
	font-size: 1rem;
	font-weight: 500;
	text-align: left;
	color: #121232;
	background-color: #fafafc;
	border: none;
}
`;
export const UiTableRow = styled(MatTableRow)`
color: ${({ theme }) => theme.table.backstage.tdColor};
transition: 0.35s;

&:nth-child(odd) {
	background-color:
		${(
	{ theme,
		isHome }
) => {
		return isHome ? theme.table.backstage.tdBgColorWhite : theme.table.backstage.tdBgColorGray;
	}};
}

&:nth-child(even) {
	background-color: ${({ theme }) => theme.table.backstage.tdBgColorWhite};
}

&:hover {
	background-color: #f5f5f5;
}

& > td {
	width: 250px;
	min-width: 100px;
	text-align: left;
	color: #121232;
	border: none;
	word-break: break-all;
	border-bottom: 1px solid #e4e7ec;
}
`;

export const InputBox = styled.div`
	display: flex;
	align-items: center;
	padding: 0 10px 10px;
`;

