export const FORGETPASSWORD = 'forgetPassword';

export const REGISTER = 'register';

export const RULE = 'rule';

export const BROWSERSUGGESTION = 'browserSuggestion';

export const EXAM_PROJECT_NAME = 'onelink';

export const SERVICE_NAME = 'OneLink';

export const COMPANY_DOMAIN = 'oneclass.com.tw';

export const COMPANY_TITLE = 'OneClass for School';

export const second = 1000;
export const minute = 60 * second;
export const hours = 60 * minute;
export const day = 24 * hours;

export const PREVIEW_TIMESTAMP = 10 * minute; // 預課時間，10分鐘

export const COMMON_ACTION_TYPE = {
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
};

export const ROLES = {
  ADMIN: 'admin',
  USER: 'user',
};

export const FREE_GROUP_ROLES = {
  HOST_CANDIDATE: 'host',
  USER: 'user',
};

export const HomeworkCardStatus = {
  EDIT: 'edit',
  READ: 'read',
};
export const AnnouncementCardStatus = {
  EDIT: 'edit',
  READ: 'read',
};

export const PostCardStatus = {
  EDIT: 'edit',
  READ: 'read',
};

export const EDIT_STATUS = {
  CREATE: 'create',
  EDIT: 'edit',
  READ: 'read',
  ALIGN_TOP: 'verticalAlignTop',
  SUBMIT: 'submit',
};

export const SUBMIT_STATUS = {
  submitted: '已繳交',
  unsubmitted: '未繳交',
  lateSubmit: '遲交',
};

// 目前沒用到
// export const PERMISSION_GROUPS = {
//   OWNER: 'owner',
//   SESSION_REPORT_MANAGEMENT: 'session_report_management',
//   SESSION_MANAGEMENT: 'session_management',
//   ORGANIZATION_SETTING_MANAGEMENT: 'organization_setting_management',
//   RESOURCE_MANAGEMENT: 'resource_management',
//   STAFF_PERMISSION_MANAGEMENT: 'staff_permission_management',
//   STAFF_MANAGEMENT: 'staff_management',
//   SESSION_MONITORING_MANAGEMENT: 'session_monitoring_management',
//   CUSTOMER_MANAGEMENT: 'customer_management',
//   GROUP_MANAGEMENT: 'group_management'
// };

export const PERMISSIONS = {
  // session
  VIEW_SESSION_REPORT: 'view_session_report',
  MANAGE_SESSION: 'manage_session',
  MONITOR_SESSION: 'monitor_session',

  // organization
  VIEW_ORGANIZATION_SETTING: 'view_organization_setting',
  EDIT_ORGANIZATION_SETTING: 'edit_organization_setting',

  // resource
  VIEW_RESOURCE: 'view_resource',
  MANAGE_RESOURCE: 'manage_resource',

  // staff
  MANAGE_STAFF_PERMISSION: 'manage_staff_permission',
  VIEW_STAFF_PROFILE: 'view_staff_profile',
  ADD_STAFF: 'add_staff',
  EDIT_STAFF_PROFILE: 'edit_staff_profile',

  // customer
  VIEW_CUSTOMER_PROFILE: 'view_customer_profile',
  INVITE_CUSTOMER: 'invite_customer',
  EDIT_CUSTOMER_PROFILE: 'edit_customer_profile',

  // group
  VIEW_GROUP: 'view_group',
  MANAGE_GROUP: 'manage_group',
};

export const DASHBOARD_SUMMARY_CATEGORY = {
  GROUP: 'group',
  STAFF: 'staff',
  CUSTOMER: 'customer',
  RESOURCE: 'resource',
  USER: 'user',
  HOSTCANDIDATE: 'hostCandidate',
  ANNOUNCEMENT: 'announcement',
  MISSION: 'mission',
  POST: 'post',
  SESSION: 'session',
};

export const ATTENDANCE_STATUS = {
  LATE: 'late',
  LEAVE_EARLY: 'leave_early',
  ON_TIME: 'on_time',
  ABSENCE: 'absence',
};

// 學制
export const EDUCATION_LEVEL = {
  ELEMENTARY: 'E',
  JUNIOR: 'J',
  SENIOR: 'H',
  OTHER: 'other',
};

// 年級
export const EDUCATION = {
  [EDUCATION_LEVEL.ELEMENTARY]: [
    { label: '1 年級', value: '1' },
    { label: '2 年級', value: '2' },
    { label: '3 年級', value: '3' },
    { label: '4 年級', value: '4' },
    { label: '5 年級', value: '5' },
    { label: '6 年級', value: '6' },
  ],
  [EDUCATION_LEVEL.JUNIOR]: [
    { label: '7 年級', value: '7' },
    { label: '8 年級', value: '8' },
    { label: '9 年級', value: '9' },
  ],
  [EDUCATION_LEVEL.SENIOR]: [
    { label: '10 年級', value: '10' },
    { label: '11 年級', value: '11' },
    { label: '12 年級', value: '12' },
  ],
};

export const SESSION_START_TIME_TYPE = {
  YET: 'YET',
  ALREADY: 'ALREADY',
  PROCESSING: 'PROCESSING',
};

export const setPremiumStatus = {
  pending: '申請中',
  trial: '體驗版',
  approved: '已開通',
  expired: '已過期',
};

export const ORGANIZATION_SETTINGS_VALUE = {
  ALLOW: 'allow',
  DISALLOW: 'disallow',
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  PRIVATE: 'private',
  PUBLIC: 'public',
  NONE: 'none',
  HOST: 'host',
  DEFAULT: 'default',
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

export const GROUP_SETTINGS_VALUE = {
  ALLOW: 'allow',
  DISALLOW: 'disallow',
};

export const HOMEWORK_SETTINGS_VALUE = {
  GROUP: 'group',
  OWNER: 'owner',
};

export const availableEntryNavigation = {
  session: 'sessions',
  brief: 'info',
  announcement: 'announcement',
  post: 'paste',
  album: 'album',
  mission: 'homeworks',
  counseling: 'secretdiscuss',
  webResource: 'webResource',
  member: 'member',
  setting: 'setting',
  onlineQuiz: 'exam',
  multimediaLearning: 'video',
  supplementaryResource: 'supplementary',
  attendance: 'attendance',
  onlineExam: 'onlineExam',
  extraLearning: 'extraLearning',
};

export const ONEPAPER_STATUS = {
  loginCookieName: 'nani_oneclass_serviceRoot',
};

export const recordTypeList = [
  {
    name: '全選',
    value: 0,
  },
  {
    name: '正常打卡',
    value: 1,
  },
  {
    name: '補打卡',
    value: 2,
  },
  {
    name: '未打卡',
    value: 8,
  },
  {
    name: '請假',
    value: 4,
  },
];

// 本班學生: group，單一學生: student, 分發給多個班級: multi
export const CURRENT_CLASS_STUDENT = 'group';
export const SINGLE_STUDENT = 'student';
export const MULTI_CLASS = 'multi';

export const CLASSIFICATION = {
  SELF_LEARNING_CLASS: 'selfLearningClass',
  PROBLEM_SOLVING_CLASS: 'problemSolvingClass',
};
export const CLASSIFICATION_LABEL_MAP = {
  [CLASSIFICATION.SELF_LEARNING_CLASS]: '自習教室',
  [CLASSIFICATION.PROBLEM_SOLVING_CLASS]: '解題教室',
};
export const SELF_LEARNING_CLASS = '自習教室';
export const PROBLEM_SOLVING_CLASS = '解題教室';

export const EDU_OBJ = {
  E: '國小',
  J: '國中',
  H: '高中',
};

export const GRADES_OBJ = {
  G01: '小一',
  G02: '小二',
  G03: '小三',
  G04: '小四',
  G05: '小五',
  G06: '小六',
  G07: '國一',
  G08: '國二',
  G09: '國三',
  G10: '高一',
  G11: '高二',
  G12: '高三',
};

export const SUBJECTS_OBJ = {
  CH: '國語',
  CN: '華語',
  EN: '英語',
  MA: '數學',
  NA: '自然與生活科技',
  SO: '社會',
  LI: '生活',
  PE: '健康與體育',
  PC: '國文',
  BI: '生物',
  PY: '理化',
  EA: '地球科學',
  GE: '地理',
  HI: '歷史',
  CT: '公民',
  CO: '綜合活動',
  TC: '科技',
  EW: '英文',
  PH: '物理',
  CE: '化學',
  CS: '公民與社會',
};

export const SESSION_STATUS = {
  NORMAL: 'normal',
  DONE: 'done',
  CANCELED: 'canceled',
};

export const SESSION_TYPE = {
  NORMAL: 'normal', // 一般課程
  INTERACTIVE: 'interactive', // 互動課程
  VIDEO: 'video', // 視訊課程
  COLLABORATIVE: 'collaborative', // 協作課程
};

/**
 * @property individualLiveCourse 一對一家教課
 * @property groupLiveCourse 團體家教課
 * @property individualCambridge 劍橋英檢1對1
 * @property individualTutorialCenterCourse 一對一補習班課
 * @property groupTutorialCenterCourse 團體補習班課
 * @property individualLearningBarCourse 學Bar個人課
 */
export const COURSE_TYPE_MAPPING = {
  individualLiveCourse: '家教個人課',
  groupLiveCourse: '家教團體課',
  individualLearningBarCourse: '學Bar個人課',
  individualCambridge: '劍橋英檢1對1',
  individualTutorialCenterCourse: '一對一補習班課',
  groupTutorialCenterCourse: '團體補習班課',
};

export const ONE_BOARD_CLASS_TYPE = {
  individualTutorialCenterCourse: 'sync-single',
  groupTutorialCenterCourse: 'sync-multiple',
  individualLearningBarCourse: 'sync-single',
};

export const SUBJECT = {
  ALL: 'ALL', // 全科
  CH: 'CH',
  CN: 'CN',
  EN: 'EN',
  MA: 'MA',
  NA: 'NA',
  SO: 'SO',
  LI: 'LI',
  PE: 'PE',
  PC: 'PC',
  BI: 'BI',
  PY: 'PY',
  EA: 'EA',
  GE: 'GE',
  HI: 'HI',
  CT: 'CT',
  CO: 'CO',
  TC: 'TC',
  EW: 'EW',
  PH: 'PH',
  CE: 'CE',
  CS: 'CS',
};

// OnePaper 傳來之格式
// OutputStyle
//   {
//       [Description("線上測驗")]
//       Online,
//       [Description("紙本卷類")]
//       Files,
//       [Description("速測")]
//       Quick,
//       [Description("自主練習")]
//       SelfLearning,
//       [Description("叢書成卷")]
//       Series
//   }
export const OUTPUT_TYPE_NAME_KEY = {
  ONLINE: 'Online',
  FILES: 'Files',
  QUICK: 'Quick',
  SELF_LEARNING: 'SelfLearning',
  SERIES: 'Series',
};
// OneLink 後端的 outputTypeName 格式
export const OUTPUT_TYPE_NAME = {
  [OUTPUT_TYPE_NAME_KEY.ONLINE]: '線上測驗',
  [OUTPUT_TYPE_NAME_KEY.FILES]: '紙本卷類',
  [OUTPUT_TYPE_NAME_KEY.QUICK]: '速測',
  [OUTPUT_TYPE_NAME_KEY.SELF_LEARNING]: '自主練習',
  [OUTPUT_TYPE_NAME_KEY.SERIES]: '叢書成卷',
};

const ORGANIZATION_GROUP = '/home/:organizationId/class/:classId';
// 停用的頁面
export const DEACTIVATION_PAGES = [
  {
    PATH: `${ORGANIZATION_GROUP}/exam`,
    COMPONENT_NAME: 'HomeExamPage',
    LABEL: '線上派卷',
    DEACTIVATION_TIMESTAMP: Date.UTC(2023, 9, 12, 16, 0, 0, 0), // 停用時間 2023/10/13 00:00:00
  },
  {
    PATH: `${ORGANIZATION_GROUP}/supplementary`,
    COMPONENT_NAME: 'HomeSupplementaryMaterialsPage',
    LABEL: '教學資源',
    DEACTIVATION_TIMESTAMP: Date.UTC(2023, 9, 12, 16, 0, 0, 0), // 停用時間 2023/10/13 00:00:00
  },
];
