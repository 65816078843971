import { FORGETPASSWORD, REGISTER, RULE, BROWSERSUGGESTION } from 'constants/index';
import {
  LoginPage,
  CustomerServicePage,
  HomeRulePage,
  BrowserSuggestionPage,
  LineConnectedPage,
  QRCodePage,
  QRCodeLoginPage,
} from 'components';
import { PATH } from './constants';

const routes = [
  {
    path: PATH.LOGIN,
    component: LoginPage,
    exact: true,
  },
  {
    path: PATH.QR_CODE,
    component: QRCodePage,
    exact: true,
  },
  {
    path: PATH.QR_CODE_LOGIN,
    component: QRCodeLoginPage,
    exact: true,
  },
  {
    path: `/${FORGETPASSWORD}`,
    component: CustomerServicePage,
    exact: true,
    serviceStatus: FORGETPASSWORD,
  },
  {
    path: `/${REGISTER}`,
    component: CustomerServicePage,
    exact: true,
    serviceStatus: REGISTER,
  },
  {
    path: `/${RULE}`,
    component: HomeRulePage,
    exact: true,
  },
  {
    path: `/${BROWSERSUGGESTION}`,
    component: BrowserSuggestionPage,
    exact: true,
  },
  {
    path: PATH.LINE_CONNECTED,
    component: LineConnectedPage,
    exact: true,
  },
];

export default routes;
