import forest from 'assets/images/forest.svg';
import { Table, Button, EmptyPrompt } from 'components';
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getStudentAllVideoRecord } from 'services/api/home/video';
import { usePageVisibility } from 'utils/hooks/usePageVisibility';
import { useSetState } from 'utils/hooks/useSetState';
import { paginationFilter } from 'utils/pagination';
import { UiHomeVideoHistoryTable } from './HomeVideoHistoryTable.style';


/**
 * 查看學生觀看紀錄
 */

export const HomeVideoHistoryTable = () => {
  const isVisible = usePageVisibility();
  const history = useHistory();
  const { organizationId, classId, userId } = useParams();
  const [
    {
      data,
      nowPage,
      rowsPage,
    }, setState] = useSetState({
      data: [],
      nowPage: 0,
      rowsPage: 100,
    });

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const schema = {
    item: {
      name: '品項',
      defaultValue: '-'
    },
    name: {
      name: '影片名稱',
      defaultValue: '--'
    },
    time: {
      name: '影片時長',
      defaultValue: '--'
    }
  };

  const fetchStudentVideoRecord = async () => {
    const res = await getStudentAllVideoRecord({ classId, studentId: userId });
    if (res.isSuccess) {
      const nextData = res.data.sort((a, b) => b.addAt - a.addAt);
      setState({ data: nextData });
    }
  };

  useEffect(() => {
    fetchStudentVideoRecord();
  }, [isVisible]);

  const tableData = paginationFilter(data, nowPage, rowsPage);
  return (
    <UiHomeVideoHistoryTable>
      <div className="flex">
        <h4>觀看紀錄列表</h4>
        <Button
          buttonColor="new"
          onClick={() => history.push(`/home/${organizationId}/class/${classId}/video?tab=0`)}>
          返回上一頁
        </Button>
      </div>
      <Table
        data={tableData}
        schema={schema}
        changePage_Rows={changePage_Rows}
        totalPage={data.length}
        nowPage={nowPage}
      />
      {tableData.length === 0 && <EmptyPrompt img={forest} message='該學生尚無任何觀看紀錄' messageColor={'#8B90A0'} />}
    </UiHomeVideoHistoryTable>
  );
};
