import { OUTPUT_TYPE_NAME, OUTPUT_TYPE_NAME_KEY } from 'constants/index';
import { Button, Icon, Label, Loading, Table, HomeOnlineExamNoDataScreen } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOrgSharedPapers, getOrgSharedPapersByName } from 'services/api/organization/sharedPapers';
import { useClass } from 'store/class';
import { useAlert } from 'utils/hooks/useAlert';
import { ONLINE_EXAM_REQUEST_TYPES, ONLINE_EXAM_LABEL_COLOR } from '../HomeOnlineExam/HomeOnlineExam';
import { UiExamIcons } from './HomeExamPaperOrganization.style';

const SCHEMA = {
  year: {
    name: '學年度',
    defaultValue: '',
  },
  eduSubjectName: {
    name: '學制科目',
    defaultValue: '',
  },
  name: {
    name: '試卷名稱',
    defaultValue: '',
  },
  paperType: {
    name: '試卷類型',
    defaultValue: '',
  },
};

export const HomeExamPaperOrganization = ({ filterData, onCreateExam, onPrompt }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [organizationPaperApiData, setOrganizationPaperApiData] = useState(); // 機構試卷
  const [pagination, setPagination] = useState({
    nowPage: 0,
    rowsPage: 10,
    totalPage: 0,
  });
  const [
    {
      myClasses: {
        dataInfo: { users: classUsers },
      },
    },
  ] = useClass();
  const { organizationId } = useParams();
  const { setAlert } = useAlert();

  /* 操作 */
  const ActionComponents = ({ params }) => {
    const { eduSubjectName, name, uid: paperId, download, downloadName, outputTypeName } = params;
    const isCantCreate = classUsers?.length > 0;
    const isFiles = outputTypeName === OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.FILES];
    const onViewSession = () => {
      const url = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/paper/preview/${paperId}`;
      window.open(url);
    };

    const downloadFile = async (u, name) => {
      const fileBlob = await fetch(u).then((res) => res.blob());
      const url = window.URL.createObjectURL(new Blob([fileBlob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    };

    return (
      <>
        <UiExamIcons>
          <div tabIndex={0}>
            <Icon.Svg name="Visibility" onClick={onViewSession} />
          </div>
          <div tabIndex={0}>
            <Icon.Svg name="DownloadFile" onClick={() => downloadFile(download, downloadName)} disabled={!download} />
          </div>
          {!isFiles && (
            <div tabIndex={0} style={{ minWidth: '88px' }}>
              <Button
                iconSvg="Plane"
                onClick={isCantCreate ? () => onCreateExam({ eduSubjectName, paperId, name }) : onPrompt}
              >
                派發測驗
              </Button>
            </div>
          )}
        </UiExamIcons>
      </>
    );
  };

  /* 試卷列表 */
  const examPaperList = useMemo(() => {
    if (!organizationPaperApiData || !organizationPaperApiData?.papers?.length) return [];
    const papers = organizationPaperApiData.papers.map((paper) => {
      // 線上測驗或速測 ? 線上測驗 : 紙本卷類
      const paperType = ONLINE_EXAM_REQUEST_TYPES.includes(paper.outputTypeName)
        ? OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.ONLINE]
        : OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.FILES];
      return {
        ...paper,
        paperType: <Label color={ONLINE_EXAM_LABEL_COLOR[paperType]}>{paperType}</Label>,
      };
    });
    return papers;
  }, [organizationPaperApiData]);

  /* 取得機構試卷 */
  const fetchOrganizationPapers = async (params) => {
    setIsLoading(true);
    const { status, data } = params?.searchKey
      ? await getOrgSharedPapersByName(organizationId, params) // 含搜尋條件
      : await getOrgSharedPapers(organizationId, params); // 不含搜尋條件
    if (status === 'success') {
      setOrganizationPaperApiData(data);
      setPagination((prev) => ({
        ...prev,
        totalPage: data?.total || 0,
      }));
    } else {
      setAlert('取得機構列表失敗', 'error');
    }
    setIsLoading(false);
  };

  /* 換頁 */
  const changePage_Rows = (params) => {
    const { newPage, newRowsPage } = params;
    setPagination((prev) => ({
      ...prev,
      nowPage: newPage,
      rowsPage: newRowsPage,
    }));
    const payload = {
      page: newPage + 1,
      limit: newRowsPage,
      searchKey: filterData.name || null,
    };
    fetchOrganizationPapers(payload);
  };

  useEffect(() => {
    const params = {
      page: pagination.nowPage + 1,
      limit: pagination.rowsPage,
    };
    fetchOrganizationPapers(params);
  }, []);

  useEffect(() => {
    if (filterData.name === null) return;
    setPagination((prev) => ({
      ...prev,
      nowPage: 0,
    }));
    const params = {
      page: 1,
      limit: pagination.rowsPage,
      searchKey: filterData.name,
    };
    fetchOrganizationPapers(params);
  }, [filterData]);

  return isLoading ? (
    <Loading />
  ) : examPaperList.length ? (
    <Table
      data={examPaperList}
      schema={SCHEMA}
      ActionComponents={ActionComponents}
      changePage_Rows={changePage_Rows}
      totalPage={pagination.totalPage}
      nowPage={pagination.nowPage}
      rowsPerPage={pagination.rowsPage}
    />
  ) : (
    <HomeOnlineExamNoDataScreen />
  );
};

HomeExamPaperOrganization.propTypes = {
  filterData: PropTypes.string,
  onCreateExam: PropTypes.func,
  onPrompt: PropTypes.func,
};
